<template>
  <div class="remains">
    <div class="is-flex is-justify-content-space-between mb-4">
      <el-button size="small" icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
      <div class="buttons">
        <el-button icon="el-icon-download" type="primary" size="small" @click="exportRemains">
          Экспорт Excel
        </el-button>
      </div>
    </div>
    <div v-if="isOpenFilter" class="is-flex is-justify-content-space-between mt-4">
      <div class="search-panel is-flex">
        <el-input
          v-model="searchTitle"
          placeholder="Поиск по названию"
          class="mr-4"
          clearable
          size="small"
        />
        <el-select v-model="searchType" placeholder="Выберите тип" style="width: 100%" size="small">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>

    <el-table :data="data" lazy>
      <el-table-column prop="title" label="Название" />
      <el-table-column prop="count" label="Количество" :formatter="formatterNumber" />
      <el-table-column prop="unit" label="Ед измерения" />
      <el-table-column prop="price" label="Cредневз. себестоимость" />
      <el-table-column prop="sum" label="Cумма остатков" :formatter="formatterNumber" />
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        class=""
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
  </div>
</template>

<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { remainsStoreModule, REMAINS_STORE_MODULE_NAME } from './remainsStoreModule';
import { useRemainsList } from './useRemainsList';

export default {
  name: 'RemainsList',
  setup() {
    if (!store.hasModule(REMAINS_STORE_MODULE_NAME)) {
      store.registerModule(REMAINS_STORE_MODULE_NAME, remainsStoreModule);
    }
    onUnmounted(() => {
      if (store.hasModule(REMAINS_STORE_MODULE_NAME)) {
        store.unregisterModule(REMAINS_STORE_MODULE_NAME);
      }
    });
    const {
      data,
      formatterNumber,
      meta,
      changePage,
      searchTitle,
      typeOptions,
      searchType,
      exportRemains,
      isOpenFilter,
      openFilter,
    } = useRemainsList();
    return {
      data,
      formatterNumber,
      meta,
      changePage,
      searchTitle,
      typeOptions,
      searchType,
      exportRemains,
      isOpenFilter,
      openFilter,
    };
  },
};
</script>
