import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import { REMAINS_STORE_MODULE_NAME } from './remainsStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useRemainsList() {
  const searchTitle = ref(routeData.query['filter[title]'] || '');
  const searchType = ref(routeData.query['filter[type]'] || '');

  const isOpenFilter = ref(false);

  const openFilter = () => {
    isOpenFilter.value = !isOpenFilter.value;
  };

  const typeOptions = [
    { label: 'Все', value: undefined },
    { label: 'Ингредиент', value: 'ingredient' },
    { label: 'Полуфабрикат', value: 'semis' },
    { label: 'Тех карта', value: 'dish' },
  ];

  watch([searchTitle, searchType], (newValue) => {
    const [title, type] = newValue;
    router.replace({
      query: {
        'filter[title]': title || undefined,
        'filter[type]': type || undefined,
      },
    });
    fetchRemains({
      filter: {
        title: routeData.query['filter[title]'] || '',
        type: routeData.query['filter[type]'],
      },
    });
  });

  const fetchRemains = (params) => {
    store.dispatch(`${REMAINS_STORE_MODULE_NAME}/fetchRemains`, params);
  };

  const exportRemains = () => {
    store
      .dispatch(`${REMAINS_STORE_MODULE_NAME}/exportRemains`, {
        page: routeData.query.page || 1,
        filter: {
          title: routeData.query['filter[title]'] || '',
          type: routeData.query['filter[type]'] || '',
        },
      })
      .then((response) => {
        const {
          data: { url },
        } = response.data;
        window.location.href = url;
      });
  };

  const meta = computed(() => store.state[REMAINS_STORE_MODULE_NAME].remainsMeta);

  const data = computed(() => store.state[REMAINS_STORE_MODULE_NAME].remainsData);

  const formatterNumber = (row, column, cellValue) => +cellValue.toPrecision(5);

  const changePage = (page) => {
    router.replace({
      query: {
        'filter[title]': searchTitle.value || undefined,
        'filter[type]': searchType.value || undefined,
        page,
      },
    });
    fetchRemains({
      page: routeData.query.page,
      filter: {
        title: routeData.query['filter[title]'] || '',
        type: routeData.query['filter[type]'] || '',
      },
    });
  };

  fetchRemains({
    page: routeData.query.page || 1,
    filter: {
      title: routeData.query['filter[title]'] || '',
      type: routeData.query['filter[type]'] || '',
    },
  });

  return {
    meta,
    data,
    formatterNumber,
    changePage,
    searchTitle,
    typeOptions,
    searchType,
    exportRemains,
    openFilter,
    isOpenFilter,
  };
}
