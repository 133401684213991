import { httpClient, parseQueryParam } from '@/app/shared/services';

export const REMAINS_STORE_MODULE_NAME = 'storage-remains';

export const remainsStoreModule = {
  namespaced: true,
  state: {
    remainsData: [],
    remainsMeta: {},
  },
  getters: {},
  mutations: {
    SET_REMAINS_DATA(state, payload) {
      state.remainsData = payload;
    },
    SET_REMAINS_META(state, payload) {
      state.remainsMeta = payload;
    },
  },
  actions: {
    fetchRemains({ commit }, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam(queryParams);
        httpClient
          .get(`/storage/remains${params}`)
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_REMAINS_DATA', data);
            commit('SET_REMAINS_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    exportRemains(ctx, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam({ ...queryParams, export: true });
        httpClient
          .get(`/storage/remains${params}`)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
  },
};
